* {
  padding: 0;
  margin: 0;
}

section {
  width: 100vw;
  height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#loader-section {
  background-color: #fff;
}

.container {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 4rem;
}

.container img {
  width: 60dvw;
  max-width: 350px;
}

.loader {
  width: 2rem;
  height: 2rem;
  border: 0.2rem solid #aaa;
  border-radius: 50%;
  border-bottom-color: #000;

  animation: rotate 1.5s infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
